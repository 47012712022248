<template>
  <div class="about">
    <v-main>
      <v-container>
        <v-row justify="center">
          <v-col :cols="12" :md="7">
            <h1>TERMS OF SERVICE</h1>
            <p class="p-desc">These Terms of Service (the "Terms of Service") govern your use
            of all Web sites owned or operated by ("GradBee"), including, without limitation, the Web site currently
            located at www.GradBee.com (collectively, the "Site") and any and all associated services provided by GradBee
            in connection with the Site (together with the Site, the "Services"). The Services comprise a platform that
            enables individuals and entities who have Projects/Vacancy that need to be performed ("Clients" and such
            Projects/Vacancy or tasks, "Projects/Vacancy") to retain individuals who may be willing to perform such
            Projects/Vacancy ("Students"). Please read these Terms of Service carefully. These Terms of Service constitute
            a binding agreement between you and GradBee regarding your use of the Services. By using the Services, you
            agree to be bound by these Terms of Service and the GradBee Privacy Policy. If you do not agree to any
            provision of these agreements, you may not use the Services.</p>
            <h3>1. CLIENT TERMS AND CONDITIONS</h3>
            <p class="p-desc">In order to use the Services as a Client
              and list Projects/Vacancy on the Site (the "Client Service"), you must agree to be bound by additional terms
              and conditions applicable to the Client Service contained in a separate User Agreement. In the event of any
              conflict between the User Agreement and these Terms of Service, the User Agreement will control.</p>
            <h3>2. TERMS OF SERVICE CHANGES</h3> <p class="p-desc">GradBee may, in its sole and absolute
              discretion, change these Terms of Service from time to time, including, without limitation, in order to
              offer new features and services on or through the Site. GradBee will post a copy of these Terms of Service
              as changed on the Site under Terms and Conditions. Your continued use of the Services constitutes your
              agreement to abide by these Terms of Service as changed. If you object to any such changes, your sole
              recourse shall be to cease using the Services.</p>
            <h3>3. ELIGIBILITY TO USE THE SERVICES</h3> <p class="p-desc">Except as expressly provided below,
              the Services may only be used by individuals and entities who can form legally binding contracts under
              applicable law. No person under the age of 14 may use the Services without the supervision of a parent or
              legal guardian. Your use of the Services will be deemed to be a representation that you are of the age of
              majority or older or are using the Services with the permission of your parent or guardian.</p>
            <h3>4. ACCOUNT AND PASSWORD SECURITY</h3> <p class="p-desc">You may create your own account with
              GradBee by completing the online registration process on the Site, and you must do so if you would like to
              be considered for a Project/Vacancy. As part of the registration process, you will be asked to provide
              certain personal information. If you elect to provide information to GradBee, you agree to provide
              information that is true and accurate, and to update such information if it changes. If you elect to not
              provide that information, or if the information you provide is not complete, current, true and accurate, you
              may not be able to use some or all of the Services, including, without limitation, the ability to be
              considered for a Project/Vacancy and to get paid for your Projects/Vacancy you perform. You may also be
              required to choose a username and password. Your username and password will be your identity for purposes of
              interacting with GradBee and third parties through the Site. You shall use your account, username and
              password solely in accordance with these Terms of Service. You are solely responsible for maintaining the
              security of your username and password and any activity that occurs under your account. You will promptly
              notify GradBee via e-mail support@gradbee.com, if you learn of or suspect any unauthorized use of your
              account, username or password.</p>
            <h3>5. USER INTERACTIONS</h3> <p class="p-desc">Clients and Students may communicate with each
              other through the Services. The Services may also facilitate communications, including with other people
              that do not use the Services, via email and other means that are not part of the Services. GradBee is not
              responsible or liable for any damage or loss related to your interactions with anyone else. You are
              responsible for complying with all applicable laws, rules and regulations in the conduct of your business
              and/or your performance of any Project.</p>
            <h3>6. GRADBEE'S ROLE</h3> <p class="p-desc">GradBee makes the Services available to enable
              Students themselves to identify and determine the suitability of Clients and Projects/Vacancy and to enable
              Clients themselves to identify and determine the suitability of Students. GradBee does not direct, has no
              control over, makes no representations and does not guarantee the quality, safety or legality of advertised
              Students services, the truth or accuracy of Project listings, the qualifications, background, or identities
              of Students, the ability of Students to deliver Students services, the ability of Clients to pay for
              Students services, or that a Client or Students can or will actually complete a transaction. GradBee is not
              required to, but may, verify any information provided by Students or Clients or perform background checks on
              Students or Clients. You authorize us to make any inquiries we consider necessary to validate your identity
              by any legal means. Information provided or made available by GradBee is based solely on data that a
              Students or Client submits and GradBee provides such information solely for the convenience of its users and
              is not an introduction, endorsement or recommendation by GradBee.</p>
            <h3>7. PAYMENT; NON-CIRCUMVENTION</h3> <p class="p-desc">All work performed on Projects/Vacancy
              shall be compensated through the Services in accordance with our payment policies. You will not do anything
              to circumvent the foregoing, including by negotiating terms outside of the Services, underbidding on
              Projects/Vacancy or any other means that would cause the value of transactions processed through Services to
              be minimized or understated.</p>
            <h3>8. OWNERSHIP</h3> <p class="p-desc">The content on the Site and all aspects of the Services,
              including, without limitation, text, software, scripts, graphics, photos, sounds, music, videos, interactive
              features and the like (collectively, "GradBee Content"), and the GradBee word marks and design marks, as
              well as certain other of the names, logos and materials displayed on the Site or through the Services that
              constitute trademarks (collectively, "Marks"), are owned by or licensed to GradBee and are subject to
              copyright, trademark and other intellectual property rights Indian laws.</p>
            <h3>9. USE OF THE SERVICES</h3> <p class="p-desc">You may not reproduce, duplicate, copy, sell,
              resell or exploit for any purposes the Services, or any portion of the Services, including, without
              limitation, GradBee Content and Marks, except as authorized by these Terms of Service. You must abide by all
              copyright notices, information or restrictions contained in or associated with any GradBee Content.</p>
            <ol>Prohibited activities include, without limitation, the following:
              <li>violating any local, state, national or international law or regulation or any provision of these
                GradBee Terms of Service;
              </li>
              <li>knowingly transmitting any material that contains adware, malware, spyware, software viruses, or any
                other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any
                computer software or hardware or telecommunications equipment;
              </li>
              <li>collecting personal information, including, without limitation, account names or e-mail addresses, of
                other users from the Services through automated means or otherwise;
              </li>
              <li>impersonating any person or entity or otherwise misrepresenting your affiliation with a person or
                entity;
              </li>
              <li>interfering with or disrupting the Services or servers or networks connected to the Services, or
                disobeying any requirements, procedures, policies, or regulations of networks connected to the Services;
              </li>
              <li>attempting to decipher, decompile, disassemble, or reverse engineer any of the software comprising any
                part of the Services;
              </li>
              <li>attempting to interfere with any security feature of the Services, or circumvent, disable or interfere
                with any copyright management or other technological measure that effectively controls access to, use or
                copying of content of the Services; and
              </li>
              <li>launching any automated system, including, without limitation, “robots,” “spiders,” “offline readers,”
                etc., that accesses the Services in a manner that sends more request messages to the Services in a given
                period of time than a human can reasonably produce in the same period by using a conventional Web browser.
              </li>
            </ol>
            <h3>10. PRIVACY POLICY</h3> <p class="p-desc">You agree that GradBee may use any information
              GradBee obtains about you in accordance with the provisions of the GradBee Privacy Policy.</p>
            <h3>11. INTELLECTUAL PROPERTY INFRINGEMENT PROCEDURE</h3> <p class="p-desc">GradBee respects the
              intellectual property rights of others. Upon proper notice, GradBee will, in its discretion, remove Content
              or other applicable content that violates intellectual property rights under applicable law, suspend access
              to the Services (or any portion thereof) to any user who uses the Services in violation of such law, and/or
              terminate the accounts of repeat infringers. If you believe your work has been copied in a way that
              constitutes intellectual property rights infringement, please send GradBee’s intellectual property agent
              (listed below) a written notification of claimed infringement with all of the following information:</p>
            <ol>Prohibited activities include, without limitation, the following:
              <li>identification of the intellectual property right (e.g., copyright, trademark, etc.) and the work
                claimed to have been infringed, or, if multiple rights and works are covered by a single notification, a
                representative list of such rights and works;
              </li>
              <li>identification of the claimed infringing material and information reasonably sufficient to permit
                GradBee to locate the material on the Services;
              </li>
              <li>information reasonably sufficient to permit GradBee to contact you, such as an address, telephone
                number, and, if available, an email address;
              </li>
              <li>a statement by you that you have a good faith belief that the disputed use is not authorized by the
                intellectual property rights owner, its agent, or the law;
              </li>
              <li>a statement by you, made under penalty of perjury, that the above information in your notification is
                accurate and that you are the intellectual property rights owner or authorized to act on the owner’s
                behalf; and
              </li>
              <li>your physical or electronic signature.</li>
            </ol>
            <p class="p-desc">Please send all of the above-enumerated information to the following GradBee
              intellectual property agent:</p> <p class="p-desc">GradBee IP Agent</p> <h4 class="text14 text-left">Email: copyright@GradBee.com</h4>
            <h3>12. LINKS TO OTHER WEB SITES</h3> <p class="p-desc">The Services may contain links to third
              party Web sites or Internet resources that are not owned or controlled by GradBee. GradBee’s provision of a
              link to any other Web site or Internet resource is for your convenience only and does not signify GradBee’s
              endorsement of such other Web site or resource or its contents. GRADBEE SHALL NOT BE RESPONSIBLE FOR THE
              AVAILABILITY OF ANY THIRD PARTY WEB SITES OR RESOURCES, AND GRADBEE DOES NOT WARRANT, ENDORSE GUARANTEE OR
              ASSUME RESPONSIBILITY FOR ANY CONTENT, INFORMATION, SOFTWARE, MATERIALS OR PRACTICES OF ANY SUCH THIRD PARTY
              WEB SITES OR RESOURCES, INCLUDING, WITHOUT LIMITATION, ANY PRODUCTS OR SERVICES ADVERTISED OR OFFERED BY A
              THIRD PARTY THROUGH THE SERVICES OR THROUGH ANY LINKED WEB SITES OR FEATURED IN ANY ADVERTISING.</p>
            <h3>13. DISCLAIMER</h3> <p class="p-desc">YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR
              SOLE RISK. THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED
              UNDER APPLICABLE LAW, GRADBEE AND ITS OFFICERS, EMPLOYEES, DIRECTORS, SHAREHOLDERS, PARENTS, SUBSIDIARIES,
              AFFILIATES, AGENTS AND LICENSORS (REFERRED TO COLLECTIVELY AS "AFFILIATES") DISCLAIM ALL WARRANTIES OF ANY
              KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE SERVICES (INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY,
              FITNESS FOR A PARTICULAR USE OR PURPOSE AND NON-INFRINGEMENT). GradBee MAKES NO REPRESENTATIONs OR
              WARRANTIES ABOUT THE ACCURACY, QUALITY, RELIABILITY OR COMPLETENESS OF CONTENT AVAILABLE ON OR THROUGH THE
              SERVICES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY:</p>
            <ol>
              <li>USE OF ANY CONTENT POSTED, E-MAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE ON OR THROUGH THE
                SERVICES;
              </li>
              <li>PERSONAL INJURY OR OTHER HARM, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF THE
                SERVICES OR IN CONNECTION WITH ANY PROJECT;
              </li>
              <li>THE QUALITY, RELIABILITY, SAFETY, COMPLETENESS, FITNESS OR NATURE OF A PROJECT OR ANY SERVICES PROVIDED
                BY A STUDENTS;
              </li>
              <li>ACT OR OMISSION OF A CLIENT OR STUDENTS, INCLUDING ANY FAILURE TO COMPLY WITH ANY APPLICABLE LAW, RULE
                OR GOVERNMENTAL REGULATION;
              </li>
              <li>ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SERVERS OR ANY AND ALL PERSONAL INFORMATION OR FINANCIAL
                INFORMATION STORED THEREIN;
              </li>
              <li>ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES; AND</li>
              <li>ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY
                THIRD PARTY.
              </li>
            </ol>
            <h3>14. LIMITATION OF LIABILITY</h3> <p class="p-desc">YOU UNDERSTAND THAT TO THE FULLEST EXTENT
              PERMITTED UNDER APPLICABLE LAW, IN NO EVENT WILL GRADBEE BE LIABLE UNDER ANY THEORY OF LIABILITY (WHETHER IN
              CONTRACT, TORT, STATUTORY, OR OTHERWISE) FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
              DAMAGES, INCLUDING DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES
              (EVEN IF SUCH PARTIES WERE ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
              DAMAGES).</p>
            <h3>15. EXCLUSIONS AND LIMITATIONS</h3> <p class="p-desc">Some jurisdictions do not allow the
              exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential
              damages. Accordingly, some of the above limitations and disclaimers may not apply to you. To the extent
              GradBee may not, as a matter of applicable law, disclaim any implied warranty or limit its liabilities, the
              scope and duration of such warranty and the extent of GradBee’s liability shall be the minimum permitted
              under such applicable law.</p>
            <h3>16. INDEMNITY</h3> <p class="p-desc">You agree to indemnify, defend, and hold harmless GradBee
              and its Affiliates (the "GradBee Parties") from and against any and all claims, liabilities, damages,
              losses, costs, expenses, fees (including reasonable attorneys’ fees and costs) that such parties may incur
              as a result of or arising from (a) any information you provide to GradBee; (b) your use of the Services and
              the use of the Services under your account; or (c) your violation of these Terms of Service. GradBee
              reserves the right, at its own expense, to assume the exclusive defense and control of any matter otherwise
              subject to indemnification by you, in which event you will cooperate with GradBee in asserting any available
              defenses.</p>
            <h3>17. FORCE MAJEURE</h3> <p class="p-desc">Without limiting the foregoing, under no circumstances
              will GradBee be held liable for any delay or failure in performance due in whole in or in part to any acts
              of nature, forces, or causes beyond its reasonable control, including, without limitation, Internet
              failures, computer equipment failures, software bugs, telecommunication equipment failures, other equipment
              failures, electrical power failures, strikes, labor disputes, riots, insurrections, civil disturbances,
              shortages of labor or materials, fires, floods, earthquakes, storms, explosions, acts of God, war,
              governmental actions, orders of domestic or foreign courts or tribunals, non-performance of third parties,
              or loss of or fluctuations in heat, light, or air conditioning.</p>
            <h3>18. TERMINATION</h3> <p class="p-desc">GradBee may terminate or suspend any and all Services
              and access to the Site immediately, without prior notice or liability, if you breach any provision of these
              Terms of Service or, if applicable, a User Agreement. In the event of any such termination, the
              indemnification provisions set forth above shall survive.</p>
            <h3>19. MODIFICATIONS TO THE SERVICES</h3> <p class="p-desc">GradBee reserves the right to modify
              or discontinue the Services with or without notice to you. GradBee shall not be liable to you or any third
              party should GradBee exercise its right to modify or discontinue the Services.</p>
            <h3>20. ARBITRATION</h3> <p class="p-desc">You and GradBee agree that we will resolve any disputes
              between us through binding and final arbitration instead of through court proceedings. You and GradBee
              hereby waive any right to a jury trial of any Claim. All controversies, claims, counterclaims, or other
              disputes arising between you and GradBee relating to the Services or these Terms of Service (each a "Claim")
              shall be submitted for binding arbitration in accordance with to the JAMS/Endispute Comprehensive
              Arbitration Rules and Procedures in effect as of the date hereof, including the Optional Appeal Procedure
              provided for in such rules (the "Arbitration Rules"). The arbitrator’s decision shall be controlled by these
              Terms of Service and any of the other agreements governing your use of the Services. The arbitrator shall
              not have the power to award punitive damages against any Party. The parties agree that the arbitration will
              be kept confidential and that the existence of the proceeding and any element of it (including, without
              limitation, any pleadings, briefs, documents, or other evidence submitted or exchanged and any testimony or
              other oral submissions and awards) will not be disclosed beyond the arbitration proceedings, except as may
              lawfully be required in judicial proceedings relating to the arbitration or by applicable disclosure rules
              and regulations of securities regulatory authorities or other governmental agencies.</p>
            <p class="p-desc">You may not act as a class representative or private attorney general, nor
              participate as a member of a class of claimants, with respect to any Claim. Claims may not be arbitrated on
              a class or representative basis. The arbitrator can decide only your and/or GradBee’s individual Claims. The
              arbitrator may not consolidate or join the claims of other persons or parties who may be similarly
              situated.</p>
              <p class="p-desc">THIS SECTION LIMITS CERTAIN RIGHTS, INCLUDING THE RIGHT TO
              MAINTAIN A COURT ACTION, THE RIGHT TO A JURY TRIAL, THE RIGHT TO PARTICIPATE IN ANY FORM OF CLASS OR
              REPRESENTATIVE CLAIM, THE RIGHT TO ENGAGE IN DISCOVERY EXCEPT AS PROVIDED IN AAA RULES, AND THE RIGHT TO
              CERTAIN REMEDIES AND FORMS OF RELIEF. OTHER RIGHTS THAT YOU OR GRADBEE WOULD HAVE IN COURT ALSO MAY NOT BE
              AVAILABLE IN ARBITRATION.</p>
            <h3>21. GENERAL PROVISIONS</h3> <p class="p-desc">These Terms of Service shall be governed by and
              construed in accordance with the laws of the Indian Govt., without giving effect to any conflict of laws
              rules or provisions. If any provision of these Terms of Service shall be unlawful, void, or for any reason
              unenforceable, then that provision shall be deemed severable from these Terms of Service and shall not
              affect the validity and enforceability of any remaining provisions. The failure of GradBee to enforce any
              right or provision of these Terms of Service will not be deemed a waiver of such right or provision. These
              Terms of Service, the GradBee Privacy Policy and, if applicable, the User Agreement, together with and any
              other policies, rules or guidelines posted in connection with the Services, constitute the entire and
              exclusive agreement between GradBee and you regarding the Services, and the foregoing, as applicable,
              supersedes and replaces any prior agreements between GradBee and you regarding the Services.</p>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: 'Terms and Conditions | Gradbee',
      meta: [{
        vmid: 'description',
        name: 'description',
        content: 'These Terms of Service (the "Terms of Service") govern your use of all Web sites owned or operated by ("Gradbee"), including, without limitation, the Web site currently located at www.gradbee.com (collectively, the "Site") and any and all associated services provided by Gradbee'
      }]
    }
  }
}
</script>

<style scoped lang="scss">
  .p-desc {
    font-size: .85rem;
  }
</style>
